.searchPage__header {
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  align-items: flex-start;
  padding: 0px;
  border-bottom: 1px solid lightgray;
}

.searchPage__logo {
  object-fit: contain;
  height: 50px;
  margin-top: 40px;
  margin-right: 50px;
  margin-left: 20px;
}

.searchPage__headerBody > .search > .search__input {
  margin-top: 0;
  width: unset;
  margin: unset;
  max-width: unset;
}

.searchPage_result {
  margin: 40px 0;
}

.searchPage__resultCount {
  color: #70757a;
  font-size: 14px;
}

.searchPage__results {
  max-width: 650px;
  margin-top: 20px;
  margin-left: 240px;
  margin-bottom: 100px
}

.searchPage_resultTitle {
  text-decoration: none;
}

.searchPage_resultTitle:hover {
  text-decoration: underline;
}

.searchPage_resultTitle > h2 {
  font-weight: 500;
}

.searchPage__resultImage {
  object-fit: contain;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.searchPage_resultSnippet {
  margin-top: 10px;
}